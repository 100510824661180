<template>
    <v-content color="primary">
        <v-container fluid id="main-container" grid-list-md fill-height>
            <v-snackbar
                    v-model="snackbar"
                    :timeout="timeout"
                    top
            >
                {{ t(text) }}
                <v-btn
                        flat
                        @click="snackbar = false"
                >
                    {{t('Close')}}
                </v-btn>
            </v-snackbar>
            <v-layout
                    justify-center
                    wrap
            >
                <v-flex sm8 xs12>
                    <v-card
                            class="mx-auto"
                            :loading="loading"
                    >
                        <v-form ref="form" @submit.prevent="">
                            <v-list two-line subheader dense>
                                <v-subheader>{{t('Details')}}
                                    <v-dialog v-model="deleteDialog" max-width="290">
                                        <template v-slot:activator="{ on }">
                                            <v-btn color="error" v-on="on" small icon ripple class="ml-auto mr-0">
                                                <v-icon small>delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title class="headline">{{t('Are you sure you want to delete?')}}</v-card-title>
                                            <v-card-text>{{t('This action cannot be undone')}}
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn small color="error" @click="deleteDialog = false">{{t('Close')}}</v-btn>
                                                <v-btn outline small color="error" @click="deleteSubscriber">{{t('Delete')}}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>

                                </v-subheader>
                                <v-list-tile
                                        avatar
                                        @click=""
                                >
                                    <v-list-tile-avatar>
                                        <v-icon class="grey lighten-1 white--text">person</v-icon>
                                    </v-list-tile-avatar>
                                    <v-list-tile-content>
                                        <v-list-tile-title>{{t('Username')}}</v-list-tile-title>
                                        <p class="mb-0">{{details.username}}
                                        </p>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-list-tile
                                        avatar
                                        @click=""
                                        v-if="!details.is_superuser"
                                >
                                    <v-list-tile-avatar>
                                        <v-icon class="grey lighten-1 white--text">branding_watermark</v-icon>
                                    </v-list-tile-avatar>
                                    <v-list-tile-content>
                                        <v-text-field
                                                :label='t("Cards prefix")'
                                                type="text"
                                                :rules="[rules.rrrCounter,rules.required,rules.checkAdminZeros]"
                                                v-model="details.rrr">
                                        </v-text-field>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-list-tile
                                        avatar
                                        @click=""
                                >
                                    <v-list-tile-avatar>
                                        <v-icon class="grey lighten-1 white--text">mail</v-icon>
                                    </v-list-tile-avatar>
                                    <v-list-tile-content>
                                        <v-list-tile-title>{{t('Email')}}</v-list-tile-title>
                                        <p class="mb-0">{{details.email}}</p>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-list-tile
                                        avatar
                                        @click=""
                                >
                                    <v-list-tile-avatar>
                                        <v-icon class="grey lighten-1 white--text">person</v-icon>
                                    </v-list-tile-avatar>
                                    <v-list-tile-content>
                                        <v-text-field
                                                :label='t("First name")'
                                                type="text"
                                                v-model="details.first_name">
                                        </v-text-field>
                                    </v-list-tile-content>
                                    <v-list-tile-content>
                                        <v-text-field
                                                :label='t("Last name")'
                                                type="text"
                                                v-model="details.last_name">
                                        </v-text-field>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-list-tile
                                        avatar
                                        @click=""
                                >
                                    <v-list-tile-avatar>
                                        <v-icon class="grey lighten-1 white--text">check</v-icon>
                                    </v-list-tile-avatar>
                                    <v-list-tile-content>
                                        <v-list-tile-title>{{t('Status verification')}}</v-list-tile-title>
                                        <p class="mb-0">{{t(details.is_activated)}}
                                        </p>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-list-tile
                                        v-if="canEdit"
                                        avatar
                                        @click=""
                                >
                                    <v-list-tile-avatar>
                                        <v-icon class="grey lighten-1 white--text">account_balance_wallet</v-icon>
                                    </v-list-tile-avatar>
                                    <v-list-tile-content>
                                        <v-text-field
                                                :label='t("Balance")'
                                                type="number"
                                                :disabled="true"
                                                v-model="details.balance">
                                        </v-text-field>
                                    </v-list-tile-content>
                                    <v-list-tile-content>
                                        <v-text-field
                                                :label='t("Add money")'
                                                type="number"
                                                :rules="[rules.counter,rules.required]"
                                                v-model="addBalance">
                                        </v-text-field>
                                    </v-list-tile-content>
                                    <v-list-tile-content v-if="showCredit">
                                        <v-text-field
                                                :label='t("Credit")'
                                                type="number"
                                                :rules="[rules.counter,rules.numberReverse,rules.required]"
                                                v-model="details.credit">
                                        </v-text-field>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-list-tile
                                        avatar
                                        @click=""
                                >
                                    <v-list-tile-avatar>
                                        <v-icon class="grey lighten-1 white--text">card_membership</v-icon>
                                    </v-list-tile-avatar>
                                    <v-list-tile-content>
                                        <v-text-field
                                                :label='t("Card price (materials)")'
                                                type="number"
                                                :rules="[rules.counter,rules.number,rules.required]"
                                                v-model="details.price_card">
                                        </v-text-field>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-list-tile
                                        avatar
                                        @click=""
                                >
                                    <v-list-tile-avatar>
                                        <v-icon class="grey lighten-1 white--text">phone</v-icon>
                                    </v-list-tile-avatar>
                                    <v-list-tile-content>
                                        <v-text-field
                                                :label='t("Phone")'
                                                type="number"
                                                v-model="details.telephone">
                                        </v-text-field>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-list-tile
                                        avatar
                                        @click=""
                                >
                                    <v-list-tile-avatar>
                                        <v-icon class="grey lighten-1 white--text">place</v-icon>
                                    </v-list-tile-avatar>
                                    <v-list-tile-content>
                                        <v-text-field
                                                :label='t("Address")'
                                                type="text"
                                                v-model="details.address">
                                        </v-text-field>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-list-tile
                                        avatar
                                        @click=""
                                >
                                    <v-list-tile-avatar>
                                        <v-icon class="grey lighten-1 white--text">place</v-icon>
                                    </v-list-tile-avatar>
                                    <v-list-tile-content>
                                        <v-text-field
                                                :label='t("Zone")'
                                                type="text"
                                                v-model="details.zone">
                                        </v-text-field>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-list-tile
                                        avatar
                                        @click=""
                                >
                                    <v-list-tile-avatar>
                                        <v-icon class="grey lighten-1 white--text">comment</v-icon>
                                    </v-list-tile-avatar>
                                    <v-list-tile-content>
                                        <v-text-field
                                                :label='t("Comment")'
                                                type="text"
                                                v-model="details.comment">
                                        </v-text-field>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-list-tile>
                                    <v-btn small color="primary" class="ml-auto" @click="saveResellerInformation">{{t('Save')}}
                                    </v-btn>
                                </v-list-tile>
                            </v-list>
                        </v-form>

                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </v-content>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "ResellersEdit",
        data() {
            return {
                snack: false,
                snackColor: '',
                snackText: '',
                loading: true,
                loadingCards: true,
                loadingAviableCards: true,
                snackbar: false,
                dialog: false,
                deleteDialog: false,
                search: '',
                text: 'Oops... Something went wrong',
                timeout: 5000,
                newBalance: 0,
                addBalance: 0,
                details: {
                    address: "",
                    balance: 0,
                    cards: Array(0),
                    email: '',
                },

                headers: [
                    {
                        text: 'Label',
                        align: 'left',
                        //  sortable: false,
                        value: 'label',
                    },
                    {text: 'Action', value: 'action', sortable: false},
                    // {text: 'Calories', value: 'calories'},
                ],
                cards: [],
                aviableCards: [],
                rules: {
                    counter: value => value <= 2147483647 || this.$translate.locale['Max 2147483647'],
                    rrrCounter: value => (value <= 999 && value >= 0 && value.length <= 3) || this.$translate.locale['Min 0 Max 999'],
                    checkAdminZeros: value => ((value !== '000' && value !== '00' && value !== '0') || this.details.is_superuser) || this.$translate.locale['Only admins can have prefix "000"'],
                    number: value => {
                        const pattern = /^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/;
                        return pattern.test(value) ||this.$translate.locale['Invalid number.']
                    },
                    numberReverse: value => {
                        const pattern = /^-\d+$/;
                        return (pattern.test(value) || value <= 0) || this.$translate.locale['Invalid number.']
                    },
                    required: value => (!!value || value === 0) || this.$translate.locale['Required.'],
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || this.$translate.locale['Invalid e-mail.']
                    },
                },
            }
        },

        methods: {
            getData() {
                this.loadingCards = true;
                axios.get(`/api/resellers/${this.$route.params.id}/`)
                    .then((response) => {
                        if (response.status === 200) {
                            this.details = response.data;
                            this.cards = this.details.cards;
                            this.newBalance = this.details.balance;
                            this.loadingCards = false;
                            this.details.is_activated = this.details.is_activated ? 'Active' : 'Inactive';
                        }
                    }).catch((error) => {
                    this.text = "Connection error";
                    this.snackbar = true;
                });
            },
            deleteSubscriber() {
                axios.delete(`/api/resellers/delete/${this.$route.params.id}/`)
                    .then((response) => {
                        if (response.status === 204) {
                            this.$router.push('/resellers')
                        }
                    }).catch((error) => {
                    this.text = error.response.data.details;
                    this.snackbar = true;
                });
            },
            saveResellerInformation() {
                if (!this.$refs.form.validate()) {
                    this.text = "Fill the form correctly";
                    this.snackbar = true;
                } else {
                    this.loading = true;
                    const resellerData = {
                        username: this.details.username,
                        rrr: this.rrr,
                        first_name: this.details.first_name,
                        last_name: this.details.last_name,
                        address: this.details.address,
                        telephone: this.details.telephone,
                        zone: this.details.zone,
                        balance: this.details.balance.toString().replace(/,/g, '.'),
                        credit: this.details.credit,
                        price_card: this.details.price_card,
                        comment: this.details.comment,
                        addBalance: this.addBalance
                    };
                    axios.put(`/api/resellers/edit/${this.$route.params.id}/`, resellerData)
                        .then((response) => {
                            if (response.status === 200) {
                                // this.getData();
                                this.text = "Reseller details changed!";
                                this.snackbar = true;
                                this.dialog = false;
                                this.loading = false;
                                setTimeout(() => {
                                    this.$router.push(`/resellers/${this.$route.params.id}/details`)
                                }, 1500);
                                this.$store.dispatch('setBalance', response.data.balance);
                            }
                        }).catch((error) => {
                            this.text = error.response.data.details || 'Error while saving reseller';
                            this.snackbar = true;
                            this.dialog = false;
                    })
                }
            }
        },
        computed: {
            rrr() {
                if (this.details.rrr < 10 && this.details.rrr.length < 2) {
                    return '00' + this.details.rrr
                } else if (this.details.rrr < 100 && this.details.rrr.length < 3) {
                    return '0' + this.details.rrr
                } else {
                    return this.details.rrr.toString()
                }
            },
            showCredit(){
                // return this.details.type_user !== 'A'
                return true
            },
            canEdit(){
                return this.$store.getters.getPk !== parseInt(this.$route.params.id)
            },
            is_admin() {
                return this.details.type_user !== 'A'
            }
        },
        watch: {
            'addBalance': {
                handler(newValue) {
                    if (newValue === '') {
                        this.details.balance = this.newBalance;
                    }
                    let b = parseFloat(this.newBalance);
                    if (newValue) {
                        this.details.balance = b + parseFloat(newValue);
                    }
                }
            }
        },
        beforeCreate() {
            if (!this.$store.getters.loggedIn) {
                this.$router.push('/')
            }
            if(!this.$store.getters.isAdmin && !this.$store.getters.isOwner){
                this.$router.push('/cards')
            }
        },
        mounted() {
            this.getData();

        }
    }
</script>

<style>
    .v-list--two-line.v-list--dense .v-list__tile {
        height: 72px !important;
    }
</style>
